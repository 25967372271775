import React from "react";
import BackButton from "../components/BackButton";

const PasswordChange = () => {
  function onSubmit(event) {
    let password = event.target.password.value;
    let confirm_password = event.target.confirm_password.value;

    if (password !== confirm_password) {
      alert("Passwords don't match");
      event.preventDefault();
      return;
    }
  }
  return (
    <div className="m-5">
      <div className="container">
        <BackButton />
        <h2 className="text-center">Change Password</h2>
        <form method="post" onSubmit={onSubmit}>
          <input placeholder="Password" name="password" type="password" />
          <br />
          <input placeholder="Confirm Password" name="confirm_password" type="password" />

          <br />
          <button type="submit">Submit</button>
        </form>
      </div>
    </div>
  );
};

export default PasswordChange;
