export default class PendingShops {
    static async getPendingShops() {
      return new Promise((resolve, reject) => {
        return fetch(`/pending_shops`, {
          method: "GET",
          headers: {
            Accept: "application/json"
          }
        })
          .then((response) => {
            return response.json();
          })
          .then((shops) => {
            resolve(shops);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    static async acceptShop(id) {
      return new Promise((resolve, reject) => {
        return fetch(`/accept_shops`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({id}),
        })
          .then((response) => {
            return response.json();
          })
          .then((id) => {
            resolve(id);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    static async rejectShop(id) {
      return new Promise((resolve, reject) => {
        return fetch(`/reject_shops`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({id}),
        })
          .then((response) => {
            return response.json();
          })
          .then((id) => {
            resolve(id);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
}
