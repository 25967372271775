import React, { useRef, useState, useEffect } from "react";
import BackButton from "../components/BackButton";
import Files from "../services/Files";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const sweetalert = withReactContent(Swal);

const UploadPDF = () => {
  const fileRef = useRef();
  const [file, setFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  function sendFile(file) {
    setIsUploading(true);
    sweetalert.showLoading();

    Files.upload_pdf(file).then(() => {
      setIsUploading(false);

      sweetalert.fire({
        icon: "success",
        title: "PDF uploaded successfully",
        confirmButtonColor: "#0d6efd",
        willClose: () => {
          window.location.reload();
        }
      });
    }).catch(() => {
      setIsUploading(false);

      sweetalert.fire({
        icon: "error",
        title: "Error while uploading PDF",
        confirmButtonColor: "#0d6efd",
        willClose: () => {
          window.location.reload();
        }
      });
    });
  }

  function getFile() {
    Files.get_pdf().then((response) => {
      setFile(response);
    });
  }

  useEffect(() => {
    getFile();
  }, []);

  return (
    <div className='m-5'>
      <div className='container'>
        <BackButton />
        <div className='text-center'>
          <h2>Fishing Regulations - PDF upload</h2>
          <div className='w-25 m-auto text-center'>
            <input type='file' className='mt-2' ref={fileRef} />
            <button
              className='btn btn-primary text-center w-50 mt-3'
              onClick={() => sendFile(fileRef)}
              disabled={isUploading}>
              Upload
            </button>
          </div>
        </div>
        <div className='mt-5'>
          <h2>Latest PDF</h2>
          {file ? (
            <div className='d-flex w-100 justify-content-between'>
              <span className='fw-bold align-self-center'>{file.created_at.slice(0, 4)}'s PDF</span>
              <a href={file.pdf_url} className='btn btn-success'>
                Download
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default UploadPDF;
