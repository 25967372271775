import React, { useState, useEffect } from 'react';
import BackButton from "../components/BackButton";
import ShopForms from '../services/ShopForms';

const Onboarding = () => {
  const [shop_forms, setShopForms] = useState([]);
  
  function getShopForms() {
    ShopForms.getShopForms().then(response => {
      setShopForms(response.shop_forms);
    })
  };

  function accept_shop(id) {
    ShopForms.acceptShopForm(id);
    window.location.reload();
  }

  function reject_shop(id) {
    ShopForms.rejectShopForm(id);
    window.location.reload();
  }

  useEffect(() => {
    getShopForms();
  }, []);

  return (
    <div className="m-5">
      <div className="container">
        <BackButton />
        <h2 className="text-center">Onboarding Shops</h2>
        <table className='table table-hover table-bordered table-striped'>
          <thead className='table-secondary'>
            <tr>
              <th>ID</th>
              <th>Email</th>
              <th>Phone number</th>
              <th>Business name</th>
              <th>Message</th>
              <th>Accept</th>
              <th>Reject</th>
            </tr>
          </thead>

          <tbody>
            {shop_forms.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.email}</td>
                <td>{item.phone_number}</td>
                <td>{item.business_name}</td>
                <td>{item.message}</td>
                <td><button className="btn-success" onClick={() => accept_shop(item.id)}>Accept</button></td>
                <td><button className="btn-danger" onClick={() => reject_shop(item.id)}>Reject</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
};

export default Onboarding;