import React, { useEffect, useState } from 'react';
import BackButton from "../components/BackButton";
import ShopService from '../services/PendingShops'

const Shops = () => {
  const [shops, setShops] = useState([]);

  function syncShops() {
    ShopService.getPendingShops().then((resp) => {
      setShops(resp.shops);
    });
  }

  useEffect(() => {
    syncShops();
  }, []);

  function accept_shop(id) {
    ShopService.acceptShop(id);
    window.location.reload();
  }

  function reject_shop(id) {
    ShopService.rejectShop(id);
    window.location.reload();
  }

  return (
    <div className="m-5">
      <div className="container">
        <BackButton />
        <h2 className="text-center">Approve Shops</h2>
        <table className='table table-hover table-bordered table-striped'>
          <thead className='table-secondary'>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Street name</th>
              <th>City name</th>
              <th>Phone number</th>
              <th>Website</th>
              <th>Day from</th>
              <th>Day to</th>
              <th>Owner</th>
              <th>Accept</th>
              <th>Reject</th>
            </tr>
          </thead>

          <tbody>
            {shops.map((item) => (
              <tr key={item.id}>
                <td>{item.id}</td>
                <td>{item.name}</td>
                <td>{item.street_name}</td>
                <td>{item.city_name}</td>
                <td>{item.phone_number}</td>
                <td>{item.website}</td>
                <td>{item.day_from}</td>
                <td>{item.day_to}</td>
                <td>{item.shop_owner}</td>
                <td><button className="btn-success" onClick={() => accept_shop(item.id)}>Accept</button></td>
                <td><button className="btn-danger" onClick={() => reject_shop(item.id)}>Reject</button></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Shops;