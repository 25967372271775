import React, { Component } from "react";
import MapDataService from "../services/MapDatas";
import BackButton from "../components/BackButton";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const sweetalert = withReactContent(Swal);

export default class MapData extends Component {
  constructor(props) {
    super(props);
    this.handlePagination = this.handlePagination.bind(this);
    // this.handleFilterType = this.handleFilterType.bind(this);

    this.handleOnClickUploadCsv = this.handleOnClickUploadCsv.bind(this);
    this.uploadCsv = this.uploadCsv.bind(this);

    this.state = {
      isUploadingCsv: false,
      data: []
    };

    this.uploadCsvRef = React.createRef();
  }

  componentDidMount() {
    MapDataService.getMapData().then((resp) => {
      this.setState({ data: resp.data });
    });
  }

  // handleFilterType(event) {
  //   const newFilterWord = event.target.value;

  //   if (newFilterWord.length >= this.minLengthFilter) {
  //     this.setState({
  //       pagination: 0
  //     });
  //   }

  //   this.setState({
  //     filterWord: newFilterWord
  //   });
  // }

  handlePagination(value) {
    if (value > 0) value = 1;
    if (value < 0) value = -1;
    if (value === 0) return;

    let newPage = this.state.pagination + value;
    const maxPage = parseInt(this.coursesLenght / this.state.perPage);

    if (newPage <= 0) newPage = 0;
    if (newPage > maxPage) newPage = maxPage;
    if (maxPage <= 0) return;

    this.setState({ pagination: newPage });
  }

  get coursesLenght() {
    return this.props.courses.length;
  }

  // get minLengthFilter() {
  //   return 3;
  // }

  // get coursesFiltered() {
  //   const filter = this.state.filterWord.toUpperCase();
  //   const original = this.props.courses;

  //   if (filter.length >= this.minLengthFilter) {
  //     return original.filter((c) => {
  //       if (c.course_title) {
  //         return c.course_title.toUpperCase().indexOf(filter) > -1;
  //       }
  //       return false;
  //     });
  //   }

  //   return original;
  // }

  get courses() {
    if (this.props.courses) {
      const indexStart = this.state.pagination * this.state.perPage;
      const indexEnd = this.state.pagination * this.state.perPage + this.state.perPage;

      const sliced = this.props.courses.slice(indexStart, indexEnd);

      return sliced;
    }

    return [];
  }

  handleOnClickUploadCsv() {
    this.uploadCsvRef.current.click();
  }

  uploadCsv(e) {
    this.setState({
      isUploadingCsv: true
    });

    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("file", file);

    sweetalert.showLoading();

    MapDataService.readCsv(formData)
      .then((response) => {
        this.setState({
          isUploadingCsv: false
        });

        if (response.error) {
          sweetalert.fire({
            icon: "error",
            title: "Error while uploading CSV",
            confirmButtonColor: "#0d6efd",
            willClose: () => {
              window.location.reload();
            }
          });
          return;
        }

        if (response.success) {
          sweetalert.fire({
            icon: "success",
            title: "CSV uploaded successfully",
            html: `<p class="text-success sweetalert-text">Inserted: ${response.message.inserted}</p><p class="text-primary sweetalert-text">Updated: ${response.message.updated}</p><p class="text-danger sweetalert-text">Deleted: ${response.message.deleted}</p>`,
            confirmButtonColor: "#0d6efd",
            willClose: () => {
              window.location.reload();
            }
          });
        } else {
          sweetalert.fire({
            icon: "error",
            title: "Wrong CSV headers",
            text: response.message,
            confirmButtonColor: "#0d6efd",
            willClose: () => {
              window.location.reload();
            }
          });
        }
      })
      .catch(() => {
        this.setState({
          isUploadingCsv: false
        });

        sweetalert.fire({
          icon: "error",
          title: "Error while uploading CSV",
          confirmButtonColor: "#0d6efd",
          willClose: () => {
            window.location.reload();
          }
        });
      });

    return false;
  }

  render() {
    return (
      <div className="m-5">
        <BackButton />
        <h2>Map Data</h2>
        <div className="mb-3 d-flex align-items-end gap-3">
          <div>
            <input
              ref={this.uploadCsvRef}
              name="file"
              type="file"
              accept=".csv"
              hidden
              onChange={this.uploadCsv}
            />
            <button
              className="btn btn-success"
              onClick={this.handleOnClickUploadCsv}
              disabled={this.state.isUploadingCsv}
            >
              <span className="text-light">Upload CSV</span>
            </button>
          </div>

          <nav className="flex-shrink-1 ml-3">
            <ul className="pagination mb-0">
              <li className="page-item">
                <a className="page-link" onClick={() => this.handlePagination(-1)}>
                  <span>&laquo;</span>
                </a>
              </li>

              <li className="page-item">
                <a className="page-link" onClick={() => this.handlePagination(1)}>
                  <span>&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>

        <table className="table table-hover table-bordered table-striped">
          <thead className="table-secondary">
            <tr>
              <th>spot_id</th>
              <th>display_title</th>
              <th>sub_heading_access</th>
              <th>sub_heading_general_info</th>
              <th>species</th>
              <th>fishing_regulations</th>
              <th>gold_medal_waters</th>
              <th>sub_heading_resources</th>
              <th>url</th>
              <th>x</th>
              <th>y</th>
              <th>gen_id</th>
              <th>reg_ii</th>
              <th>name</th>
            </tr>
          </thead>

          <tbody>
            {this.state.data.map((item) => (
              <tr key={item.id}>
                <td>{item.spot_id}</td>
                <td>{item.display_title}</td>
                <td>{item.sub_heading_access}</td>
                <td>{item.sub_heading_general_info}</td>
                <td>{item.species}</td>
                <td>{item.fishing_regulations}</td>
                <td>{item.gold_medal_waters}</td>
                <td>{item.sub_heading_resources}</td>
                <td>{item.url}</td>
                <td>{item.x}</td>
                <td>{item.y}</td>
                <td>{item.gen_id}</td>
                <td>{item.reg_ii}</td>
                <td>{item.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
