import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import MapData from "./pages/MapData";
import UploadPDF from "./pages/UploadPDF";
import Shops from "./pages/Shops";
import Onboarding from "./pages/Onboarding";
import PasswordChange from "./pages/PasswordChange";

export default class Main extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path='/admin' exact component={Home} />
          <Route path='/admin/map_data' component={MapData} />
          <Route path='/admin/upload_pdf' component={UploadPDF} />
          <Route path='/admin/accept_shops' component={Shops} />
          <Route path='/admin/onboarding_shops' component={Onboarding} />
          <Route path='/admin/password' component={PasswordChange} />
        </Switch>
      </Router>
    );
  }
}
