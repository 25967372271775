import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class Home extends Component {
  render() {
    return (
      <div className="m-5">
        <h1>Woolly Buggers</h1>
        <br />
        <div>
          <Link to="/admin/map_data" className="bg-transparent text-secondary">
            Map Data
          </Link>
        </div>
        <br />
        <div>
          <Link to="/admin/upload_pdf" className="bg-transparent text-secondary">
            Fishing Regulations - PDF upload
          </Link>
        </div>
        <br />
        <div>
          <Link to="/admin/accept_shops" className="bg-transparent text-secondary">
            Approve Shops
          </Link>
        </div>
        <br />
        <div>
          <Link to="/admin/password" className="bg-transparent text-secondary">
            Change password
          </Link>
        </div>
        <br />
        {/* <div>
          <Link to="/admin/onboarding_shops" className="bg-transparent text-secondary">Onboarding Shops</Link>
        </div>
        <br/> */}
        <div>
          <a href="/admin/logout" className="bg-transparent text-secondary">
            Logout
          </a>
        </div>
      </div>
    );
  }
}
