export default class Files {
  static async get_pdf() {
    return new Promise((resolve, reject) => {
      return fetch(`/fishing_regulations`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static async upload_pdf(uploaded_media) {
    const file = uploaded_media.current.files[0];
    const data = new FormData();
    data.append("uploaded_media", file);
    return new Promise((resolve, reject) => {
      return fetch(`/upload_pdf`, {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: data
      })
        .then((response) => {
          return response;
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
