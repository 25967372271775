export default class MapDatas {
  static async getMapData() {
    return new Promise((resolve, reject) => {
      return fetch(`/map_datas/`, {
        method: "GET",
        headers: {
          Accept: "application/json"
        }
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  static async readCsv(data) {
    return new Promise((resolve, reject) => {
      return fetch(`/map_datas/read_csv`, {
        method: "POST",
        headers: {
          Accept: "application/json"
        },
        body: data
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}
