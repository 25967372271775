import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class BackButton extends Component {
  render() {
    return (
      <div className="mb-3">
        <Link to="/admin" className="bg-transparent text-secondary">Back</Link>
      </div>
    );
  }
}