export default class ShopForms {
    static async getShopForms() {
      return new Promise((resolve, reject) => {
        return fetch(`/onboarding_shop_forms`, {
          method: "GET",
          headers: {
            Accept: "application/json"
          }
        })
          .then((response) => {
            return response.json();
          })
          .then((shops) => {
            resolve(shops);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    static async acceptShopForm(id) {
      return new Promise((resolve, reject) => {
        return fetch(`/accept_shop_form`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({id}),
        })
          .then((response) => {
            return response.json();
          })
          .then((id) => {
            resolve(id);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    static async rejectShopForm(id) {
      return new Promise((resolve, reject) => {
        return fetch(`/reject_shop_form`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({id}),
        })
          .then((response) => {
            return response.json();
          })
          .then((id) => {
            resolve(id);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
}
